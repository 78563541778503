.box-sized-not-allowed {
    text-align: center;
    width: 75vw;
    max-width: 75vw;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
}

.background {
    height: 100vh;
    width: 100vw;
    background-color: white;
    position: fixed;
    z-index: -1;
}

.foreground {
    position: absolute;
    top: 0;
}

.header {
    margin-top: 4vh;
    width: 20vw;
}

.heading {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    margin: 0px;
}

.thumbnail {
    height: 24px;
    vertical-align: middle;
}